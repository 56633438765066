import {renderFormProps} from "../../../../FormController";
import {Alert, Form} from "react-bootstrap";
import React from "react";
import AbstractComment from "../../../../../model/AbstractComment";

interface CommentaireProps extends renderFormProps<AbstractComment> {}

export const ChatForm = ({register, errors}: CommentaireProps) => {
    return (
        <div className={'mb-2'}>
            <Form.Group className={"mb-3"}>
                <Form.Control as="textarea" rows={4} {...register('comment', { required: 'Votre commentaire ne peut pas être vide', maxLength: 250 })}/>
                {
                    errors.comment
                    && errors.comment
                        ? (
                            <Alert className={'bg-transparent border-0'} key={'danger'} variant={'danger'}>
                                {
                                    errors.comment?.type === 'required' ? errors.comment?.message?.toString()
                                    : '250 caractères maximum'
                                }
                            </Alert>
                        ) : null
                }
            </Form.Group>
        </div>
    )
}